import React, { useEffect, useRef, useState } from "react";
import Isotope from "isotope-layout";
import ProjectDetailsModal from "./ProjectDetailsModal";
const Portfolio = ({ classicHeader, darkTheme }) => {
  // init one ref to store the future isotope object
  const isotope = useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("*");
  const [imagesLoaded, setimagesLoaded] = useState(0);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState();

  const filters = {
   // WD: "Web Design",
  };

  const projectsData = [
    {
      title: "The Getaway - Real Estate App",
      projectInfo:
        "Real Estate website clone using React JS, Tailwind CSS and Firebase for storing, fetching data and authentication. Application allows users to login and create property listings for sale and for rent as well as view and modify their existing listings.",
      technologies: "React JS, Tailwind CSS, Firebase",
      date: "July 16, 2019",
      url: {
        name: "Live Website",
        link: "https://real-estate-web-app-eta.vercel.app",
      },
      dv: {
        name: "Live Demo Video",
        link: "https://www.loom.com/share/db424ea2efb8462cba8b378a62962479",
      },
      gitrepo: {
        name: "Github Repository Link",
        link: "https://github.com/jazir788/real-estate-web-app",
      },
      thumbImage: "images/projects/portfolio_1-1.png",
      sliderImages: [
        "images/projects/portfolio_1-2.png",
        "images/projects/portfolio_1-3.png",
        "images/projects/portfolio_1-4.png",
        
      ],
      categories: [filters.WD],
    },
    {
      title: "Full Stack Web Chat App",
      projectInfo:
        "A full stack web chat application that allows two users to communicate online.",
      technologies: "React JS, Socket.io and Node JS",
      url: {
        name: "Live Website",
        link: "https://jazir-web-chat-app.netlify.app/",
      },
      dv: {
        name: "Live Demo Video",
        link: "https://www.loom.com/share/e582145840af4b9ca4b79c0438d5c6a7",
      },
      gitrepo: {
        name: "Github Repository Link",
        link: "https://github.com/jazir788/WebChatApp",
      },
      thumbImage: "images/projects/portfolio_2.png",
      categories: [filters.WD],
    },
    {
      title: "Hangman Game",
      projectInfo:
        "using React Typescript to put into practice React Typescript Learning",
      technologies: "React Typescript",
      url: {
        name: "deployment in progress",
      //  link: "https://jazir-web-chat-app.netlify.app/",
      },
      dv: {
        name: "Live Demo Video",
        link: "https://www.loom.com/share/9fc3ae74ea994ce6a3f9e338a2eff0ca",
      },
      gitrepo: {
        name: "Github Repository Link",
        link: "https://github.com/jazir788/Hangman-game",
      },
      thumbImage: "images/projects/portfolio_3-1.png",
      sliderImages: [
        "images/projects/portfolio_3-2.png",
      ],
      categories: [filters.DESIGN, filters.PHOTOS],
    },
  ];

  // initialize an Isotope object with configs
  useEffect(() => {
    isotope.current = new Isotope(".portfolio-filter", {
      itemSelector: ".filter-item",
      layoutMode: "masonry",
    });

    // cleanup
    return () => {
      isotope.current.destroy();
    };
  }, []);

  // handling filter key change
  useEffect(() => {
    if (imagesLoaded) {
      filterKey === "*"
        ? isotope.current.arrange({ filter: `*` })
        : isotope.current.arrange({ filter: `.${filterKey}` });
    }
  }, [filterKey, imagesLoaded]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <>
      <section
        id="portfolio"
        className={"section " + (darkTheme ? "bg-dark-2" : "bg-light")}
      >
        <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
          {/* Heading */}
          <div className="position-relative d-flex text-center mb-5">
            <h2
              className={
                "text-24  text-uppercase fw-600 w-100 mb-0 " +
                (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
              }
            >
              Portfolio
            </h2>
            <p
              className={
                "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
                (darkTheme ? "text-white" : "text-dark")
              }
            >
              {" "}
              My Work
              <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
            </p>
          </div>
          {/* Heading end*/}
          {/* Filter Menu */}
          <ul
            className={
              "portfolio-menu nav nav-tabs justify-content-center border-bottom-0 mb-5 " +
              (darkTheme ? "nav-light" : "")
            }
          >
            <li className="nav-item">
              <button
                className={"nav-link " + (filterKey === "*" ? "active" : "")}
                onClick={handleFilterKeyChange("*")}
              >
                Web Design
              </button>
            </li>
            {Object.keys(filters).map((oneKey, i) => (
              <li className="nav-item" key={i}>
                <button
                  className={
                    "nav-link " +
                    (filterKey === filters[oneKey] ? "active" : "")
                  }
                  onClick={handleFilterKeyChange(filters[oneKey])}
                >
                  {filters[oneKey]}
                </button>
              </li>
            ))}
          </ul>
          {/* Filter Menu end */}
          <div className="portfolio popup-ajax-gallery">
            <div className="row portfolio-filter filter-container g-4">
              {projectsData.length > 0 &&
                projectsData.map((project, index) => (
                  <div
                    className={
                      "col-sm-6 col-lg-4 filter-item " +
                      project.categories.join(" ")
                    }
                    key={index}
                  >
                    <div className="portfolio-box rounded">
                      <div className="portfolio-img rounded">
                        <img
                          onLoad={() => {
                            setimagesLoaded(imagesLoaded + 1);
                          }}
                          className="img-fluid d-block portfolio-image"
                          src={project.thumbImage}
                          alt=""
                        />
                        <div className="portfolio-overlay">
                          <a
                            className="popup-ajax stretched-link"
                            href=""
                            onClick={() => {
                              setSelectedProjectDetails(projectsData[index]);
                            }}
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal"
                          />
                          <div className="portfolio-overlay-details">
                            <h5 className="text-white fw-400">
                              {project.title}
                            </h5>
                            {/*<span className="text-light">Category</span>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section>
      <div className="project-details-modal">
        {/* Modal */}
        <ProjectDetailsModal
          projectDetails={selectedProjectDetails}
          darkTheme={darkTheme}
        ></ProjectDetailsModal>
      </div>
    </>
  );
};

export default Portfolio;
